/* eslint-disable max-lines */
import { rgba } from 'polished';
import {getConfig} from '@/app/utils/general';
import {
  IButton,
  ICheckboxFiled,
  ICheckmark,
  IClose,
  ICpfFiled,
  ICreditCardFiled,
  ICvvField,
  IDateField,
  IFiledError,
  IIcon,
  IInput,
  IInputFiled,
  ILabel,
  ILink,
  IParagraph,
  IScrollTop,
  ISelect,
  ISelectFiled,
  ITextareaField,
  ITitle,
  IStepper,
  ICallus,
  IDropDown,
  IExpanded,
  IExpandedContent,
  IHamburger,
  IHelper,
  IIconInfo,
  ILanguagePicker,
  IModal,
  ICopyright,
  ISearcher,
  ITag,
  IRating,
  ITestimonial,
  ITestimonialSingle,
  ITestimonialCarousel,
  ITestimonialItem,
  IVideoCoverCarousel,
  IItemBox,
  IPriceDistribution,
  ITooltip,
  IError,
  IIndicator,
  ICarouselNavigation,
  ICarousel,
  ICarouselItem,
  IBackdropMenu,
  ICookies,
  INotifications,
  IDataBox,
  IMainCarouselItem,
  IHeader,
  IChangeMail,
  IFooter,
  IIconText,
  ISeparator,
  IInputNumberField,
  IListItem,
  IGameItem,
  ITagWrapper,
} from '../../theme.interface';

const config = getConfig();
const { colors, fontFamily, others, GRID_BREAKPOINTS, CONTAINER_MAX_WIDTHS } = config.STYLEGUIDE;

const ICON: IIcon = {
  fill: colors.white,
};

const BUTTON: IButton = {
  ICON,
  background: colors.brand,
  border: 'none',
  brand: colors.brand,
  brandHover: colors.infoHover,
  danger: colors.brand,
  dangerHover: colors.error,
  disabled: colors.medium,
  fontColor: colors.white,
  fontSize: '14px',
  fontWeight: '700',
  info: colors.info,
  infoHover: colors.infoHover,
  success: colors.brand,
  successHover: colors.infoHover,
  warning: colors.warning,
  warningHover: rgba(colors.warning, 0.2),
};

const CHECKMARK: ICheckmark = {
  border: colors.buttonSecond,
  borderChecked: colors.buttonSecond,
  background: colors.input,
  backgroundChecked: colors.brand,
  borderActive: colors.buttonSecond,
  backgroundActive: colors.brand,
  backgroundActiveFull: colors.input,
};

const CLOSE: IClose = {
  height: 12,
  width: 1,
  color: colors.medium,
};

const FIELDERROR: IFiledError = {
  error: colors.error,
};

const INPUT: IInput = {
  background: colors.input,
  backgroundActive: colors.input,
  backgroundError: 'rgba(250, 76, 76, 0.1)',
  border: colors.buttonSecond,
  borderActive: colors.brand,
  borderError: colors.error,
  color: colors.white,
  colorError: colors.error,
  fontSize: '12px',
  fontWeight: '400',
  placeholderColor: '#6f6d6d',
  placeholderColorActive: colors.white,
  placeholderErrorColor: colors.error,
};

const LABEL: ILabel = {
  color: colors.white,
  fontSize: '14px',
  lineHeight: '16px',
};

const LINK: ILink = {
  activeColor: colors.white,
  background: colors.brand,
  border: 'none',
  buttonColor: colors.white,
  color: colors.white,
  fontSize: '14px',
  fontSizeBtn: '14px',
  fontWeight: '700',
  hoverBackground: colors.infoHover,
  hoverColor: colors.brand,
  lineHeight: '16px',
};

const PARAGRAPH: IParagraph = {
  GRID_BREAKPOINTS,
  color: '#c0bdbd',
  types: {
    p1: { color: '#c0bdbd', fontSize: '18px', lineHeight: '28px' },
    p2: { color: '#c0bdbd', fontSize: '14px', lineHeight: '24px' },
    p3: { color: '#c0bdbd', fontSize: '13px', lineHeight: '20px' },
    p4: { color: '#c0bdbd', fontSize: '12px', lineHeight: '18px' },
    p5: {
      color: '#c0bdbd',
      fontSize: '10px',
      lineHeight: '14px',
      textAlign: 'center',
    },
  },
};

const SCROLLTOP: IScrollTop = {
  ICON,
  background: colors.brand,
  border: '1px solid #20c3d0',
  borderRadius: '50%',
};

const TITLE: ITitle = {
  GRID_BREAKPOINTS,
  h1: {
    color: colors.white,
    fontFamily: 'Montserrat',
    fontSize: '32px',
    fontWeight: '700',
    letterSpacing: '-0.4px',
    lineHeight: '40px',
    md: {
      color: colors.white,
      fontFamily: 'Montserrat',
      fontSize: '40px',
      fontWeight: '700',
      letterSpacing: '0.2px',
      lineHeight: '52px',
    },
  },
  h2: {
    color: colors.white,
    fontFamily: 'Montserrat',
    fontSize: '28px',
    fontWeight: '700',
    lineHeight: '40px',
    md: {
      color: colors.white,
      fontFamily: 'Montserrat',
      fontSize: '28px',
      fontWeight: '700',
      letterSpacing: '-0.35px',
      lineHeight: '40px',
    },
  },
  h3: {
    color: colors.white,
    fontFamily: 'Montserrat',
    fontSize: '24px',
    fontWeight: '700',
    lineHeight: '30px',
  },
  h4: {
    color: colors.white,
    fontFamily: 'Montserrat',
    fontSize: '16px',
    fontWeight: '700',
    lineHeight: '24px',
  },
  h5: {
    color: colors.white,
    fontFamily: 'Montserrat',
    fontSize: '13px',
    fontWeight: '700',
    lineHeight: '24px',
  },
};

const SELECT: ISelect = {
  arrow: colors.medium,
  background: colors.input,
  border: colors.buttonSecond,
  backgroundError: 'rgba(250, 76, 76, 0.1)',
  borderError: colors.error,
  color: colors.white,
  colorHover: colors.info,
  error: colors.error,
  fontSize: '12px',
  fontSizeOption: '12px',
};

const CHECKBOXFIELD: ICheckboxFiled = {
  CHECKMARK,
  FIELDERROR,
  INPUT,
  LABEL,
  PARAGRAPH,
  color: colors.white,
  error: colors.error,
};

const CREDITCARDFIELD: ICreditCardFiled = {
  FIELDERROR,
  INPUT,
  LABEL,
  iconColor: colors.medium,
};

const CPFFIELD: ICpfFiled = {
  ...CREDITCARDFIELD,
};

const CVVFIELD: ICvvField = {
  ...CREDITCARDFIELD,
  iconFill: colors.medium,
  iconFillHover: colors.medium,
};

const DATEFIELD: IDateField = {
  FIELDERROR,
  LABEL,
  SELECT,
  color: colors.white,
  error: colors.error,
};

const INPUTFIELD: IInputFiled = {
  FIELDERROR,
  INPUT,
  LABEL,
  color: colors.white,
  error: colors.error,
};

const SELECTFIELD: ISelectFiled = {
  FIELDERROR,
  LABEL,
  SELECT,
};

const TEXTAREAFIELD: ITextareaField = {
  FIELDERROR,
  LABEL,
  background: colors.input,
  backgroundActive: colors.input,
  backgroundError: 'rgba(250, 76, 76, 0.1)',
  border: colors.buttonSecond,
  borderActive: colors.brand,
  borderError: colors.error,
  borderRadius: '4px',
  color: colors.white,
  error: colors.error,
  fontSize: '12px',
  placeholderColor: '#6f6d6d',
  placeholderColorActive: colors.white,
  placeholderErrorColor: colors.error,
};

const STEPPER: IStepper = {
  CHECKMARK: {
    ...CHECKMARK,
    backgroundChecked: colors.brand,
  },
  color: '#c0bdbd',
};

const CALLUS: ICallus = {
  LINK: {
    ...LINK,
    fontWeight: '400',
    fontSize: '13px',
  },
  PARAGRAPH,
  color: '#c0bdbd',
  borderColor: colors.input,
  brand: colors.brand,
};

const DROPDOWN: IDropDown = {
  BUTTON: {
    ...BUTTON,
    background: colors.buttonSecond,
  },
  buttonBackground: colors.buttonSecond,
  buttonBorderColor: colors.buttonSecond,
  buttonBackgroundOpen: colors.buttonSecond,
  buttonBorderOpen: colors.brand,
  buttonTextColor: colors.white,
  buttonTextColorOpen: colors.white,
  buttonTextFontSize: '12px',
  carretColor: colors.medium,
  carretColorOpen: colors.medium,
  iconColor: colors.medium,
  listBackground: colors.buttonSecond,
  listBorder: colors.buttonSecond,
  listCarretColor: colors.brand,
  listElementBackgroundHover: colors.overlay,
  listElementFontSize: '12px',
  listElementText: colors.white,
  listElementTextHover: colors.white,
  fontWeight: '700',
};

const EXPANDED: IExpanded = {
  LINK,
  PARAGRAPH,
  active: colors.brand,
  borderBottom: colors.input,
  hover: colors.brand,
  iconPlusColor: colors.medium,
};

const EXPANDEDCONTENT: IExpandedContent = {
  EXPANDED,
  TITLE,
};

const HAMBURGER: IHamburger = {
  color: colors.medium,
  hoverLineBackground: colors.white,
  border: colors.buttonSecond,
  background: colors.buttonSecond,
  borderRadius: '4px',
  borderHover: colors.medium,
  backgroundHover: colors.medium,
};

const HELPER: IHelper = {
  TITLE,
  PARAGRAPH,
  dotBackground: others.textColor,
  background: colors.input,
  borderColor: colors.input,
  borderRadius: '4px',
};

const ICONINFO: IIconInfo = {
  color: others.textColor,
  iconFill: colors.brand,
};

const LANGUAGEPICKER: ILanguagePicker = {
  DROPDOWN: {
    ...DROPDOWN,
  },
};

const MODAL: IModal = {
  background: colors.background,
  border: colors.background,
  borderRadius: '4px',
  wrapperBackground: rgba(colors.dropdownDisabled, 0.75),
};

const COPYRIGHT: ICopyright = {
  PARAGRAPH: {
    ...PARAGRAPH,
    color: '#c0bdbd',
  },
};

const SEARCHER: ISearcher = {
  ...INPUT,
  GRID_BREAKPOINTS,
  background: colors.input,
  backgroundActive: colors.input,
  borderActive: colors.brand,
  btnBackground: colors.buttonSecond,
  btnBorder: colors.buttonSecond,
  btnBackgroundExpanded: 'transparent',
  iconFill: colors.medium,
};

const TAG: ITag = {
  CHECKMARK,
  active: colors.info,
  background: colors.buttonSecond,
  borderColor: colors.buttonSecond,
  checkedColor: colors.white,
  checkmarkColor: colors.buttonSecond,
  color: colors.white,
  fontSize: '13px',
  fontWeight: '700',
};

const RATING: IRating = {
  fill: colors.warning,
  fillBack: colors.medium,
};

const INDICATOR: IIndicator = {
  activeColor: colors.brand,
  pipColor: colors.input,
};

const SWIPER_CAROUSEL = {
  INDICATOR,
};

const TESTIMONIAL: ITestimonial = {
  GRID_BREAKPOINTS,
  PARAGRAPH,
  RATING,
  titleColor: '#c0bdbd',
  titleFontWeight: '400',
  fontColor: '#c0bdbd',
  borderColor: '',
  background: colors.input,
};

const TESTIMONIAL_SINGLE: ITestimonialSingle = {
  GRID_BREAKPOINTS,
  PARAGRAPH,
  RATING,
  TITLE,
  titleColor: '#c0bdbd',
  fontFamily: 'Montserrat',
  fontColor: '#c0bdbd',
  borderColor: '',
  background: colors.input,
};

const TESTIMONIAL_CAROUSEL: ITestimonialCarousel = {
  SWIPER_CAROUSEL,
  TESTIMONIAL_NAVIGATION: {
    background: '#25242E',
    backgroundHover: '#007CF0',
    border: '1px solid #636272',
    borderHover: '1px solid #007CF0',
    borderRadius: '5px',
    padding: '13px',
    iconColor: '#636272',
    iconColorHover: '#FFFFFF',
    iconWidth: '12px',
    iconHeight: '12px',
    leftButtonSpaceRight: '52px',
  },
  background: '#1E1D25',
  border: '1px solid #636272',
  borderRadius: '8px',
  padding: '24px 20px',
  type: 2,
};

const TESTIMONIAL_ITEM: ITestimonialItem = {
  RATING,
  PARAGRAPH,
};

const VIDEO_COVER_CAROUSEL: IVideoCoverCarousel = {
  GRID_BREAKPOINTS,
  SWIPER_CAROUSEL,
  background: colors.dark,
  borderColor: colors.medium,
  backgroundIcon: colors.brand,
  colorIcon: colors.white,
};

const ITEMBOX: IItemBox = {
  PARAGRAPH,
  TITLE,
};

const PRICEDISTRIBUTION: IPriceDistribution = {
  CHECKMARK,
  PARAGRAPH,
  TITLE,
  color: colors.white,
  subtitleColor: '#c0bdbd',
  activeColor: colors.white,
};

const TOOLTIP: ITooltip = {
  CLOSE,
  PARAGRAPH,
  TITLE,
  background: colors.input,
  borderRadius: '4px',
  borderColor: colors.input,
  cardFill: colors.brand,
};

const ERROR: IError = {
  BUTTON,
  PARAGRAPH,
  TITLE,
  color: colors.dark,
};

const CAROUSEL_NAVIGATION: ICarouselNavigation = {
  BUTTON,
  backgroundColor: colors.buttonSecond,
  borderColor: colors.buttonSecond,
  iconColor: colors.medium,
  backgroundHoverColor: colors.infoHover,
  borderHoverColor: colors.infoHover,
  iconHoverColor: colors.white,
};

const CAROUSEL: ICarousel = {
  BUTTON,
  CAROUSEL_NAVIGATION,
  GRID_BREAKPOINTS,
  TITLE,
  INDICATOR: {
    activeColor: colors.brand,
    pipColor: colors.input,
  },
};

const CAROUSELITEM: ICarouselItem = {};

const BACKDROPMENU: IBackdropMenu = {
  LINK: {
    ...LINK,
    fontSize: '24px',
    lineHeight: '30px',
    fontWeight: '700',
  },
  itemActive: colors.brand,
  itemBorder: colors.input,
  background: colors.background,
};

const COOKIES: ICookies = {
  BUTTON,
  CLOSE,
  LINK,
  color: '#c0bdbd',
  background: colors.input,
  borderRadius: '4px',
  borderColor: colors.input,
};

const NOTIFICATIONS: INotifications = {
  PARAGRAPH: {
    ...PARAGRAPH,
    color: colors.white,
    types: {
      ...PARAGRAPH.types,
      p1: {
        ...PARAGRAPH.types.p1,
        color: colors.white,
        md: {
          color: colors.white,
        },
      },
    },
  },
  dangerBackground: colors.error,
  infoBackground: colors.info,
  warningBackground: colors.warning,
  successBackground: colors.brand,
};

const DATABOX: IDataBox = {
  BUTTON: {
    ...BUTTON,
    fontColor: colors.white,
    info: colors.buttonSecond,
    infoHover: colors.infoHover,
  },
  INPUT: {
    ...INPUT,
    background: colors.dropdownDisabled,
    color: '#6f6d6d',
    placeholderColor: '#6f6d6d',
    placeholderColorActive: '#6f6d6d',
  },
  LABEL,
  borderButton: colors.buttonSecond,
  border: colors.inputDisabled,
};

const GAMEITEM: IGameItem = {
  borderRadius: '30px',
  background: colors.overlay,
  starHover: colors.warning,
  iconFill: colors.medium,
  removeBackground: colors.transparent,
  removeBackgroundHover: colors.brand,
  borderColor: colors.transparent,
  borderColorHover: colors.brand,
  iconColorHover: colors.white,
  titleHoverBackground: colors.background,
  CLOSE: {
    height: 13,
    width: 1,
    color: colors.medium,
  },
  TITLE,
  ICONINFO,
  LINK,
  GRID_BREAKPOINTS,
};

const MAIN_CAROUSEL_ITEM: IMainCarouselItem = {
  GRID_BREAKPOINTS,
  CONTAINER_MAX_WIDTHS,
  TITLE: {
    ...TITLE,
    h3: {
      ...TITLE.h3,
      color: colors.white,
    },
  },
  PARAGRAPH: {
    ...PARAGRAPH,
    types: {
      ...PARAGRAPH.types,
      p3: {
        ...PARAGRAPH.types.p3,
        color: colors.white,
      },
    },
  },
  ICONINFO: {
    ...ICONINFO,
    fontSize: '14px',
    color: colors.white,
  },
  BUTTON,
  paragraphFontFamily: fontFamily.main,
  iconTextStyled: colors.white,
  viewDetailsColor: colors.white,
  clockIconColor: colors.warning,
  starOutlineIconColor: colors.success,
  gradientFirstColor: colors.background,
  gradientSecondColor: rgba(colors.background, 0),
  gradientThirdColor: colors.background,
  gradientFirstColorPercent: 'calc(50% - 700px)',
  gradientSecondColorPercent: 'calc(50% + 700px)',
  gradientThirdColorPercent: 'calc(50% + 700px)',
};

const HEADER: IHeader = {
  CONTAINER_MAX_WIDTHS,
  GRID_BREAKPOINTS,
  stickyHeaderBackground: colors.background,
  stickyHeaderBorder: colors.input,
};

const CHANGEEMAIL: IChangeMail = {
  color: '#FFFFFF',
  background: '#25242E',
  border: '#636272',
  buttonColor: '#FFFFFF',
  buttonBorder: '#636272',
  buttonBackground: '#25242E',
};

const FOOTER: IFooter = {
  GRID_BREAKPOINTS,
  border: colors.input,
  background: colors.input,
};

const ICONTEXT: IIconText = {
  PARAGRAPH: {
    ...PARAGRAPH,
    color: '#FFFFFF',
  },
  background: '#2F2E37',
};

const SEPARATOR: ISeparator = {
  color: colors.input,
};

const INPUTNUMBERFIELD: IInputNumberField = {
  INPUT,
  LABEL,
  FIELDERROR,
};

const LISTITEM: IListItem = {
  fontSize: '13px',
  color: '#c0bdbd',
};

const TAGWRAPPER: ITagWrapper = {
  background: colors.lighter,
  borderColor: colors.lighter,
};

const sectionTheme = {
  GRID_BREAKPOINTS,
  skeleton: {
    primaryColor: colors.light,
    secondaryColor: colors.lighter,
  },
};

const HEADER_NO1 = { ...sectionTheme, TITLE, PARAGRAPH, LINK };

const FEATURES_NO1 = { ...sectionTheme, LINK };

const PREMIUM_NO1 = { ...sectionTheme, LINK, checkIconColor: colors.brand, arrowColor: colors.link };

const TESTIMONIALS_NO1 = { ...sectionTheme };

const PREFOOTER_NO5 = { ...sectionTheme, TITLE, PARAGRAPH, LINK };

export const theme = {
  BUTTON,
  CHECKBOXFIELD,
  CHECKMARK,
  CLOSE,
  CPFFIELD,
  CREDITCARDFIELD,
  CVVFIELD,
  DATEFIELD,
  FIELDERROR,
  ICON,
  INPUT,
  INPUTFIELD,
  LABEL,
  LINK,
  PARAGRAPH,
  SCROLLTOP,
  SELECT,
  SELECTFIELD,
  TEXTAREAFIELD,
  TITLE,
  STEPPER,
  CALLUS,
  DROPDOWN,
  EXPANDED,
  EXPANDEDCONTENT,
  HAMBURGER,
  HELPER,
  ICONINFO,
  LANGUAGEPICKER,
  MODAL,
  GAMEITEM,
  COPYRIGHT,
  SEARCHER,
  TAG,
  RATING,
  TESTIMONIAL,
  TESTIMONIAL_SINGLE,
  TESTIMONIAL_CAROUSEL,
  TESTIMONIAL_ITEM,
  VIDEO_COVER_CAROUSEL,
  ITEMBOX,
  PRICEDISTRIBUTION,
  TOOLTIP,
  ERROR,
  INDICATOR,
  CAROUSEL_NAVIGATION,
  CAROUSEL,
  CAROUSELITEM,
  BACKDROPMENU,
  COOKIES,
  NOTIFICATIONS,
  DATABOX,
  MAIN_CAROUSEL_ITEM,
  HEADER,
  CHANGEEMAIL,
  FOOTER,
  ICONTEXT,
  SEPARATOR,
  INPUTNUMBERFIELD,
  LISTITEM,
  SWIPER_CAROUSEL,
  HEADER_NO1,
  FEATURES_NO1,
  PREMIUM_NO1,
  TESTIMONIALS_NO1,
  PREFOOTER_NO5,
  TAGWRAPPER,
};

export default theme;
